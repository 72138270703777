import React, {createContext, useReducer, useEffect, useState} from "react"
import { useStaticQuery, graphql } from "gatsby"

const initialState = {
  lang: "en",
  stars: [],
  startSearch: false,
  searchTerm: ""
}

const GlobalContext = createContext({
  lang: "en",
  stars: [],
  startSearch: false,
  searchTerm: ""
})

function GlobalProvider({ children }) {
  const data = useStaticQuery(graphql`
    query {
      allOffer {
        nodes {
          locales {
            iso
            value
            title
          }
          imageFile {
            childImageSharp {
              gatsbyImageData(quality: 50)
            }
          }
        }
      }
    }
  `)
  const [offers, setOffers] = useState(data.allOffer.nodes)

  const globalReducer = (state, action) => {
    switch (action.type) {
      case "changeLanguage":
        return { ...state, lang: action.payload }
      case 'setFavourites': 
        return {...state, stars: [...action.payload]}
      case "addFavourite":
        if(state.stars.find(f => f === action.payload) === undefined) {
          return { ...state, stars: [...state.stars, action.payload]}
        }else {
          return state
        }
      case "removeFavourite":
        return { ...state, stars: state.stars.filter(s => s !== action.payload)}
      case "startSearch":
         return {...state, startSearch: action.payload}
      case "searchTerm":
         return { ...state, searchTerm: action.payload}
      default:
        throw new Error()
    }
  }
  const [state, dispatch] = useReducer(globalReducer, initialState)

  return (
    <GlobalContext.Provider
      value={{
        lang: state.lang,
        stars: state.stars,
        startSearch: state.startSearch,
        searchTerm: state.searchTerm,
        dispatch,
        offers
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
export { GlobalProvider }
